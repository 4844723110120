import * as Sentry from '@sentry/react';
import currency from 'currency.js';
import merge from 'lodash/merge';
import { LocalStorage } from '@qb/frontend/utils/localStorage';
import { webQueries } from '@qb/httpRequest/webQueries';
import { SPS_HOST } from '@qb/sps/src/SPSConstants';
import { UserInfo } from '@/shared/types/controllers/AuthControllerTypes';
import { GA4EventName, GA4Params } from './ga4Types';

export const recordEvent = async (
  eventName: GA4EventName,
  overwriteParams: GA4Params = {},
) => {
  try {
    const isOnSPS = !!SPS_HOST;

    if (!currentCartValue && !isOnSPS) {
      const cartData = await webQueries.cart.navbar.displayData.queryFn();
      setCartValue(currency(cartData.cartValue, { fromCents: true }));
    }

    let isBuyer, isSupplier, isManufacturer;
    if (currentUser) {
      isBuyer = currentUser.company.isBuyer.toString();
      isSupplier = currentUser.company.isSupplier.toString();
      isManufacturer = currentUser.company.isManufacturer.toString();
    }
    const baseParams: GA4Params = {
      page: {
        info: {
          name:
            document.getElementsByTagName('title')[0]?.textContent ?? undefined,
          url: window.location.href,
          path: window.location.pathname,
          referrer: previousRoute || document.referrer || window.location.href,
          environment: process.env.ENV_NAME,
          hostname: window.location.hostname,
        },
        attributes: {
          is_widget_embed: salesPilotAppID ? 'true' : 'false',
          widget_id: salesPilotAppID,
          cart_value: currentCartValue?.value,
        },
      },
      user: {
        profile: {
          login_status: currentUser ? 'logged-in' : 'logged-out',
          id: currentUser?.id,
          email: currentUser?.email,
          type: 'user',
          company_id: currentUser?.company.id,
          company_is_buyer: isBuyer,
          company_is_supplier: isSupplier,
          company_is_manufacturer: isManufacturer,
        },
        attributes: {
          lt_transactions: currentUser?.stat_lt_orders,
          ltv: currentUser?.stat_ltv,
          account_created_date: currentUser?.createdAt,
          account_age: currentUser
            ? monthDiff(new Date(currentUser.createdAt), new Date())
            : undefined,
        },
      },
    };
    const params = merge(baseParams, overwriteParams);

    const urlParams = new URLSearchParams(window.location.search);
    const analyticsDebug =
      urlParams.get('analyticsDebug') || LocalStorage.getItem('analyticsDebug');

    if (analyticsDebug) {
      // eslint-disable-next-line no-console
      console.log('GA4 debug: ', eventName, params);
    }
    return pushToDataLayer(eventName, params);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    Sentry.captureException(err);
  }
};

let salesPilotAppID: number | undefined;
export const setGA4SalesPilotAppID = (appID: number | undefined) => {
  salesPilotAppID = appID;
};
let currentUser: UserInfo | undefined;
export const setGA4CurrentUser = (user: UserInfo | undefined) => {
  currentUser = user;
};
let currentCartValue: currency | undefined;
export const setCartValue = (cartValue: currency | undefined) => {
  currentCartValue = cartValue;
};

let previousRoute: string | undefined;
export const setPreviousRoute = (prevRoute: string | undefined) => {
  previousRoute = prevRoute;
};

const pushToDataLayer = (eventName: GA4EventName, params: GA4Params) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      ...params,
      event: eventName,
    });
  }
};

function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
